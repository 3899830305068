



















































































































    @use "@material/floating-label/mdc-floating-label";
    @use "@material/line-ripple/mdc-line-ripple";
    @use "@material/notched-outline/mdc-notched-outline";
    @use "@material/textfield";
    @use "@material/textfield/helper-text";
    @use "@material/textfield/character-counter";
    @use "@material/textfield/icon";

    @include icon.icon-core-styles;
    @include textfield.core-styles;

    .profile .mdc-text-field	{
      @include textfield.outlined-height(34px);
      width: 85%;
    }
    .profile .mdc-text-field.mdc-text-field--disabled {
      width: 90%;
    }

    .error-report .mdc-text-field	{
      @include textfield.outlined-height(150px);
      @include textfield.placeholder-color(var(--color-text-primary));
      @include textfield.fill-color(var(--color-text-primary));
      border-radius: 5px;
      padding: 0;
      text-decoration: none;

      textarea {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        text-decoration: none;
        resize: none;
      }

    }
    .error-report .input-container-size {
      width: 100%;
    }

    #stock-comments .mdc-text-field {
      @include textfield.outlined-height(70px);
      @include textfield.placeholder-color(var(--color-text-primary));
      @include textfield.fill-color(var(--color-text-primary));
      border-radius: 5px;
      padding: 0;

      textarea {
        resize: none;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
      }
    }

    #stock-comments .input-container-size {
      width: 100%;
    }

    textarea {
      resize: none;
    }
    
    .mdc-text-field {
    @include textfield.disabled-fill-color(var(--color-surface));
    @include textfield.ink-color(var(--color-text-primary));
    @include textfield.label-color(var(--color-text-primary));
    @include textfield.placeholder-color(var(--color-text-primary));
    @include textfield.disabled-ink-color(var(--color-text-primary));
    @include textfield.outline-color(var(--color-text-primary));
    @include textfield.hover-outline-color(var(--color-text-primary));  // Add this line
    --mdc-theme-primary: #0E5F85FF;
}

    .mdc-text-field-character-counter {
    margin: 0px;}

    .mdc-text-field-helper-line.red {
    padding: 0;}

    .mdc-text-field-character-counter {
    color: rgba(0, 0, 0, 0.6);}

    .mdc-text-field {
    padding: 0 0 0 15px;}

    .mdc-text-field--outlined .mdc-text-field__resizer {
      resize: none;
    } 


    // .mdc-text-field--invalid {
    //     @include textfield.label-color(rgba(255, 0, 0, 1));
    //     @include textfield.ink-color(rgba(255, 0, 0, 1));
    //     @include textfield.caret-color(rgba(255, 0, 0, 1)); //esto modifica el color del cursor

    //     .mdc-text-field-helper-text {
    //         @include helper-text.helper-text-color(rgba(255, 0, 0, 1));
    //     }
    // }

    @media (max-width: 599px){
    .mdc-text-field	 {
        width: 100%;
        min-width: 100px;}
    }

    @media (min-width: 600px) and (max-width: 839px) {
    .mdc-text-field	 {
        width: 100%;
        min-width: 180px;}
    }

    @media (min-width: 840px){
    .mdc-text-field	 {
        width: 100%;
        min-width: 100px;}
    }
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
       -moz-appearance:textfield;
    }

    #alarms{
      .value_input_key_to_save{
        width: 100% !important;
        margin-left: 20px;
      }
    }
